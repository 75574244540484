import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './Contact.scss';

const ContactPage = () => {
  // State to store form data and reCAPTCHA token
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  // Update form data on user input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Update reCAPTCHA token in state
  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  // Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  setSubmitting(true);

  // Check if reCAPTCHA token is available
  if (!recaptchaValue) {
    alert("Please confirm you're not a robot");
    setSubmitting(false);
    return;
  }

  // Prepare the request payload with the `data` object
  const payload = {
    data: {
      ...formData,
      // recaptcha: recaptchaValue, // You might need to handle this separately depending on your backend logic
    }
  };

  try {
    // Post form data to Strapi backend
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/contact-forms`, // Updated endpoint with /api prefix
      payload, // Updated payload
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    // Display success message and reset form and captcha
    setMessage("Thank you for submitting your message. We'll get in touch with you soon.");
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setRecaptchaValue(null);
  } catch (error) {
    // Handle errors and display message to user
    setMessage('Form submission failed. Please try again.');
    console.error('Form submission error:', error.response?.data || error.message);
  } finally {
    // Reset the submitting state regardless of the outcome
    setSubmitting(false);
  }
};


  return (
    <div className="contact-form">
      {message && (
        <div className={message.includes("Thank you for submitting your message") ? 'success-message' : 'error-message'}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {/* ... other form fields ... */}
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} />
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Use environment variable for site key
          onChange={handleRecaptcha}
        />
        <button type="submit" disabled={submitting}>
          {submitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default ContactPage;
