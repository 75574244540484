import React, { useEffect, useState } from 'react';
import axios from '../../../services/axiosConfig';
import { useParams } from 'react-router-dom';
import './BlogPost.scss';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api/blog-articles?filters[slug][$eq]=${slug}&populate=Images`);
        if (response.data.data.length > 0) {
          setPost(response.data.data[0]);
        } else {
          // Handle the case where no post is found
          console.log('No post found');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  const { Title, Content, Images } = post.attributes;
  const imageUrl = Images.data && Images.data.length > 0
    ? Images.data[0].attributes.url
    : 'default-image-path'; // Provide your default image path here

  // Adjust how you render content based on your structure
  // This assumes your Content is an array of blocks like the example you've provided
  const renderContent = (content) => {
    // Assuming 'content' is an array of text blocks or similar
    return content.map((block, index) => {
      // Adjust rendering logic based on the actual structure of 'block'
      if (block.type === "paragraph") {
        return <p key={index}>{block.children.map(child => child.text).join('')}</p>;
      }
      // Add more conditions for other content types if needed
      return null; // Fallback for unrecognized block types
    });
  };

  return (
    <div className="blog-post">
      <img className="blog-post-image" src={imageUrl} alt={`Cover for ${Title}`} />
      <h1 className="blog-post-title">{Title}</h1>
      <div className="blog-post-content">
        {/* Assuming Content is an array of paragraphs or similar */}
        {Content ? renderContent(Content) : null}
      </div>
    </div>
  );
};

export default BlogPost;
