import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MatomoTagManager = () => {
  const location = useLocation();

  useEffect(() => {
    const loadMatomoTagManager = () => {
      if (!window._mtm) {
        const _mtm = window._mtm = window._mtm || [];
        _mtm.push({ 'mtm.startTime': (new Date()).getTime(), event: 'mtm.Start' });
        const g = document.createElement('script'),
          s = document.getElementsByTagName('script')[0];
        g.async = true;
        g.src = process.env.REACT_APP_MATOMO_URL; // Use environment variable for Matomo URL
        s.parentNode.insertBefore(g, s);
      }
    };

    loadMatomoTagManager();
  }, []);

  useEffect(() => {
    if (window._mtm) {
      window._mtm.push({ event: 'mtm.PageView', url: window.location.href });
    }
  }, [location]);

  return null;
};

export default MatomoTagManager;
