import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import './App.scss';
import Header from './stories/components/header/Header';
import Footer from './stories/components/Footer/Footer';
import Homepage from './stories/components/homepage/homepage';
import BlogPost from './stories/components/blog/BlogPost';
import BlogList from './stories/components/blog/BlogList'; 
import About from './stories/components/about/About'; 
import Contact from './stories/components/contact/Contact';
import MatomoTagManager from './MatomoTagManager'; // Import MatomoTagManager

function App() {
  return (
    <div className="App">
      <MatomoTagManager /> {/* Include MatomoTagManager component */}
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        {/* Define more routes as needed */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
